import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo"; 
import Navbar from "../components/_App/Navbar";
import Footer from "../components/_App/Footer";
import PageBanner from '../components/Common/PageBanner';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion';

import Engineering1 from "../assets/images/engineering1.jpg";
import Engineering2 from "../assets/images/engineering.png";

const Engineering = () => (
    <Layout>
        <SEO title="Engineering Services" /> 

        <Navbar />

        <div className="features-details-area ptb-80">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 features-details-image">
                        <img 
                            src={Engineering1} 
                            className="animate__animated animate__fadeInUp" 
                            alt="image" 
                        />
                    </div>

                    <div className="col-lg-6 features-details">
                        <div className="features-details-desc">
                            <h3>Engineering Services</h3>
                            <p>Our engineering team is comprised of mechanical, electrical, embedded software, and industrial engineers and can meet the following engineering needs.</p>

                            <ul className="features-details-list">
                                <li>Embedded electronics and IoT</li>
                                <li>Energy, power generation, and thermodynamic systems</li>
                                <li>Robotics and autonomous systems</li>
                                <li>Product design, from problem statement, to design for manufacturing</li>
                                <li>Trade studies and technical literature reviews</li>
                                <li>Project and program management</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="separate"></div>

                <div className="row align-items-center">
                    <div className="col-lg-6 features-details">
                        <div className="features-details-desc">
                            <p>Our engineering team works seamlessly with Faraya's designers, software development team, and researchers to ensure that your solution is elegant, optimized, and effective.</p>

                            <div className="features-details-accordion">
                                <Accordion allowZeroExpanded preExpanded={['a']}>
                                    <AccordionItem uuid="a">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <span>
                                                What are your engineering capabilities?
                                                </span>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                            The engineering team is comprised of mechanical, electrical, embedded software, and industrial engineers and specializes in solving complex problems requiring multidisciplinary teams and cutting edge technology. The engineering team can accelerate your project by conducting research, developing conceptual designs and sketches, preliminary designs and systems models, detailed designs using CAD and eCAD and simulation, and design for manufacturing.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem uuid="b">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <span>
                                                How do you solve complex problems?
                                                </span>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                            Your problem statement will first be reviewed by Faraya's engineering leadership team to determine the team size and composition. The team will then be hand selected to meet the problem specific needs. This team will be managed by a project lead and will see the project through from start to finish.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem uuid="c">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <span>
                                                Can you integrate with our team?
                                                </span>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                            The engineering team will seamless integrate with your team to add technical capabilities or augment your existing technical team by providing additional capacity.                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-6 features-details-image">
                        <img 
                            src={Engineering2} 
                            className="animate__animated animate__fadeInUp" 
                            alt="image" 
                        />
                    </div>
                </div>
            </div>
        </div>

        <Footer />
    </Layout>
)

export default Engineering;
